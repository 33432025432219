import React, { useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Lightbox from 'react-images';
import { Copy } from 'grommet-icons';
import {
  Image,
  Box,
  Text,
  Button,
  Heading
} from 'grommet';

import routes from 'routes';
import Page from 'components/page';

import permissions_explainer from 'assets/permissions_explainer.gif';
import advertising_settings from 'assets/advertising_settings.png';

const ImageWithPointer = styled(Image)`
  cursor: pointer;
  &:hover {
    filter: brightness(50%);
  }
`;

const StandardText = ({ children, ...props }) => <Text {...props} color="dark-3" size="large">{children}</Text>;

const SelectAllText = styled(StandardText)`
  user-select: all;
`;

const ExplainPermissions = ({
  store
}) => {
  //  is modal open?
  const [modalStatusGif, setModalStatusGif] = useState(false);
  const [modalStatusPng, setModalStatusPng] = useState(false);
  const [emailCopiedStatus, setEmailCopiedStatus] = useState(false);

  const handleEmailCopy = () => {
    navigator.clipboard.writeText('manage@bidmatik.com').then(() => setEmailCopiedStatus(true))
  }

  return (
    <Page
      headline="We need your permission"
      subline="Add bidmatik your Seller Central, so we can start working on your campaigns"
      onNext={() => store.router.goTo(routes.asinIntake)}
    >
      <Box
        height="medium"
        fill="horizontal"
        direction="column"
      >
        <ImageWithPointer
          src={permissions_explainer}
          fit="contain"
          onClick={() => setModalStatusGif(true)}
        />
        <Lightbox
          images={[{ src: permissions_explainer }]}
          isOpen={modalStatusGif}
          onClose={() => setModalStatusGif(false)}
        />
      </Box>

      <Box
        fill="horizontal"
        direction="column"
      >
        <Heading
          size="medium"
          level="3"
        >
          1. Add bidmatik in Seller Central
        </Heading>
        <Box
          direction="row"
          align="center"
        >
          <SelectAllText
            margin={{
              'right': '20px',
              'left': '20px'
            }}
          >
            manage@bidmatik.com
          </SelectAllText>
          <Button
            icon={<Copy />}
            label="Copy"
            primary={emailCopiedStatus}
            onClick={handleEmailCopy}
          />
        </Box>
      </Box>

      <Box
        fill="horizontal"
        direction="column"
      >
        <Heading
          size="medium"
          level="3"
        >
          2. Wait for us to accept the invitation
        </Heading>
        <Box
          direction="row"
          align="center"
        >
          <StandardText
            margin={{
              'right': '20px',
              'left': '20px'
            }}
          >
            It willl take a moment for us to confirm, you can click (next) for now. By default we will not have any permissions for your account. 
          </StandardText>
        </Box>
      </Box>
      <Box
        fill="horizontal"
        direction="column"
      >
        <Heading
          size="medium"
          level="3"
        >
          3. Go back later and set the right permissions
        </Heading>
        <Box
          direction="column"
          align="center"
        >
          <StandardText
            margin={{
              'right': '20px',
              'left': '20px'
            }}
          >
            Once we accepted your invitation, use the "manage permissions" button to allow us to manage your advertisements.
          </StandardText>
          <Box
            height="small"
            fill="horizontal"
            direction="column"
          >
            <ImageWithPointer
              src={advertising_settings}
              fit="contain"
              onClick={() => setModalStatusPng(true)}
            />
            <Lightbox
              images={[{ src: advertising_settings }]}
              isOpen={modalStatusPng}
              onClose={() => setModalStatusPng(false)}
            />
          </Box>
        </Box>
      </Box>

    </Page>
  )
};

// hackery to get hoojs working
export default observer(['store'], ({ store }) => <ExplainPermissions store={store} />);
