import React from 'react';
import Page from 'components/page';
import { observer } from 'mobx-react';

import {
  TextInput,
  Select,
  Text
} from 'grommet';

import routes from 'routes';

const EmailMarkets = ({
  store
}) =>
  <Page
    headline="Connect your Account"
    subline="Lets start with some basic info"
    onNext={() => store.router.goTo(routes.explainPermissions)}
  >
    <Text
      margin={{
        "top": "20px"
      }}
    >
      Full Name
    </Text>
    <TextInput
      placeholder="Jeff Bezos"
      value={store.app.name}
      onChange={evt => store.app.setName(evt.target.value)}
    />
    <Text
      margin={{
        "top": "20px"
      }}
    >
      Seller Central Email
    </Text>
    <TextInput
      placeholder="email@bidmatik.com"
      value={store.app.email}
      onChange={evt => store.app.setEmail(evt.target.value)}
    />
    <Text
      margin={{
        "top": "20px"
      }}
    >
      Primary Market
    </Text>
    <Select
      options={['USA', 'Canada', 'Mexico', 'United Kingdom', 'France', 'Germany', 'Italy', 'Japan']}
      value={store.app.mainMarket}
      onChange={({ option }) => store.app.setMainMarket(option)}
    />
  </Page>
  ;

export default observer(['store'], EmailMarkets);
