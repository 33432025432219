import React from 'react';
import Page from 'components/page';
import { observer } from 'mobx-react';

import {
  Accordion,
  AccordionPanel,
  Text,
  Box,
  Select,
  TextInput,
  Heading
} from 'grommet';

import routes from 'routes';

const ConfigureAsin = ({
  store
}) =>
  <Page
    headline="Set Advertisement Goals"
    subline="Choose a goal for each of your Asin's"
    onNext={() => store.router.goTo(routes.finalize)}
  >
  <Accordion
    multiple
  >
    <AccordionPanel label="Growth">
      <Box pad="medium" background="light-2">
        <Text>Improve your organic ranking by reinvesting your ad profits. Ideal for non first page products</Text>
      </Box>
    </AccordionPanel>
    <AccordionPanel label="Profit">
      <Box pad="medium" background="light-2">
        <Text>Maximize the profit with record setting low ACOS. Ideal for products with already great organic rank</Text>
      </Box>
    </AccordionPanel>
    <AccordionPanel label="Total Sales">
      <Box pad="medium" background="light-2">
        <Text>Optimize for long term growth, get your new product ranked by strategically overspending until you are at the top. Then let cruise control take over to make profits.</Text>
      </Box>
    </AccordionPanel>
  </Accordion>
  {store.app.asins.map(asin => (
    <Box
      key={asin.asin}
      border={{ color: 'brand', size: 'small' }}
      pad="small"
      margin={{
        top: '20px'
      }}
      round="small"
    >
      <Heading
        level={3}
        size="medium"
        margin="none"
      >
        ASIN: {asin.asin}
      </Heading>
      <Text
        margin={{
          "top": "20px"
        }}
      >
        Advertising Goal
      </Text>
      <Select
        options={['Profit', 'Growth', 'Total Sales']}
        value={asin.goal}
        onChange={({ option }) => store.app.setAsinGoal(asin.asin, option)}
      />
      <Text
        margin={{
          "top": "20px"
        }}
      >
        Weekly Budget
      </Text>
      <TextInput
        value={`$ ${asin.weeklyBudget}`}
        placeholder="200$"
        onChange={evt => store.app.setAsinWeeklyBudget(asin.asin, evt.target.value.split(' ')[1])}
      />
  </Box>
  ))}
</Page>
;

export default observer(['store'], ConfigureAsin);
