import React from 'react';
import Page from 'components/page';
import { observer } from 'mobx-react';

import {
  Box,
  Heading,
  Paragraph
} from 'grommet';

import Payment from 'components/Payment';
import Row from 'components/row';
import routes from 'routes';

const Finalize = ({
  store
}) =>
  <Page
    headline="Finalize"
    subline=""
    onNext={false}
  >
    <Heading level={3} size={'small'} margin={{ top: 'none', bottom: 'none' }}>Your campaigns: </Heading>
    {store.app.asins.map(asin => (
      <Row
        margin={{
          top: '20px'
        }}
        first={`${asin.asin} - ${asin.goal}`}
        second={''}
        third={`${asin.weeklyBudget} $`}
      />
    ))}
    <Row
      first={<Heading level={4} size={'small'}>Weekly Budget Total</Heading>}
      second={''}
      third={<Heading level={4} size={'small'}>{`${store.app.asins.reduce((sum, asin) => sum + Number(asin.weeklyBudget), 0)} $`}</Heading>}
    />
    <Payment />

  </Page>
  ;

export default observer(['store'], Finalize);
