import React from 'react';
import { Route } from 'mobx-router';

import EmailMarkets from 'views/EmailMarkets';
import ExplainPermissions from 'views/ExplainPermissions';
import AsinIntake from 'views/AsinIntake';
import ConfigureAsin from 'views/ConfigureAsin';
import Finalize from 'views/Finalize';

export default {
  index: new Route({
    path: '/',
    component: <EmailMarkets/>
  }),
  explainPermissions: new Route({
    path: 'explainPermissions',
    component: <ExplainPermissions />
  }),
  asinIntake: new Route({
    path: 'asinIntake',
    component: <AsinIntake />
  }),
  configureAsin: new Route({
    path: 'configureAsin',
    component: <ConfigureAsin/>
  }),
  finalize: new Route({
    path: 'finalize',
    component: <Finalize/>
  })
};
