import React from 'react';
import Page from 'components/page';
import StripeForm from './StripeForm';
import { Elements } from 'react-stripe-elements';
import routes from 'routes';

export default () =>
  <Elements>
    <StripeForm />
  </Elements>
  ;
