import React from 'react';
import { Box } from 'grommet';

export default ({
  first,
  second,
  third,
  ...props
}) => (
  <Box
  direction="row"
  fill="horizontal"
  justify="between"
  {...props}
  >
    <Box
      direction="row"
    >
      <Box
        width="small"
      >
        {first}
      </Box>
      {second}
    </Box>
    {third}
  </Box>
);
