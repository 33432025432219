import React from 'react';
import {
  Box,
  Heading,
  Form,
  Text,
  Button,
} from 'grommet';
import { CardElement } from 'react-stripe-elements';
import { injectStripe } from 'react-stripe-elements';
import { observer } from 'mobx-react';

const StripeForm = ({
  store,
  stripe
}) =>
  <Form onSubmit={() => store.app.createPayment(stripe)}>
    <Box
      margin={{
        top: '20px',
        bottom: '20px'
      }}
      border={{ color: 'brand', size: 'small' }}
      pad="small"
      round="small"
    >
      <Box
        direction="row"
        justify="between"
      >
        <Heading level={3} size={'small'} margin={{ top: 'none' }}>{`Bidmatik x ${store.app.asins.length}: `}</Heading>
        <Heading level={3} size={'small'} margin={{ top: 'none' }}>{`${store.app.price} $`}</Heading>
      </Box>
      <Text margin={{ bottom: 'small'}}>
        You will only pay for the Bidmatik service now
      </Text>
      <CardElement style={{ base: { fontSize: '18px' } }} />
    </Box>
    <Button type="submit" primary label="Submit" />
  </Form>
;

export default observer(['store'], injectStripe(StripeForm));
