import React from 'react';
import Page from 'components/page';
import { observer } from 'mobx-react';

import { 
  TextInput,
  Text,
  Button,
  Box
} from 'grommet';
import {
  Add,
  Trash
} from 'grommet-icons';
import styled from '@emotion/styled';

import routes from 'routes';

const TrashWithPointer = styled(Trash)`
  cursor: pointer;
`;

const AsinIntake = ({
  store
}) =>
  <Page
    headline="Products to advertise"
    subline="Enter the ASINS you want to advertise"
    onNext={() => store.router.goTo(routes.configureAsin)}
  >
    <Text
      margin={{
        "top": "20px"
      }}
    >
      ASIN
    </Text>
    <Box
      direction="row"
    >
      <TextInput
        placeholder="B00JF8381G"
        onChange={evt => store.app.setCurrentAsin(evt.target.value)}
        value={store.app.currentAsin}
        onKeyPress={evt => evt.key === 'Enter' ? store.app.addCurrentAsin() : null }
      />
      <Button
        icon={<Add />}
        onClick={store.app.addCurrentAsin}
      />
    </Box>
    {store.app.asins.map(asin => (
      <Box
        key={asin.asin}
        border={{ color: 'brand', size: 'small' }}
        pad="small"
        margin={{
          top: '20px'
        }}
        round="small"
      >
        <Box
          direction="row"
          justify="between"
        >
          {asin.asin}
          <TrashWithPointer
            onClick={() => store.app.deleteAsin(asin)}
          />
        </Box>
      </Box>
    ))}
  </Page>
  ;

export default observer(['store'], AsinIntake);
