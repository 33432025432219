import React from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Heading,
  Button
} from 'grommet';

const ButtonFixedPos = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

export default ({
  headline,
  subline,
  children,
  onNext,
}) =>
  <Box
    direction="column"
    pad="medium"
    align="center"
  >
    <Box
      width="600px"
    >
      <Heading
        level={1}
        size="medium"
        color="dark-1"
      >
        {headline}
      </Heading>

      <Heading
        level={2}
        size="small"
        color="dark-4"
        margin="none"
        >
        {subline}
      </Heading>

      {children}

    </Box>
      { onNext && 
        <ButtonFixedPos
          label="Next"
          width="25px"
          margin={{
            // "bottom": "-200px"
          }}
          primary
          onClick={onNext}
        />
      }
  </Box>
  ;
