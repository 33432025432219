import React from 'react';
import {
  action,
  extendObservable,
  computed,
} from 'mobx';
import { Provider } from 'mobx-react';
import { 
  MobxRouter,
  RouterStore,
  startRouter
} from 'mobx-router';
import { StripeProvider } from 'react-stripe-elements';
import DevTools from 'mobx-react-devtools';
import { Grommet } from 'grommet';

import routes from './routes';

class AppStore {
  constructor() {
    extendObservable(this, {
      name: '',
      email: '',
      mainMarket: 'USA',
      asins: [],
      currentAsin: '',
      get price() {
        console.log(Number(process.env.REACT_APP_BIDMATIK_UNIT_PRICE))
        return Number(process.env.REACT_APP_BIDMATIK_UNIT_PRICE) * this.asins.length;
      }
    });
  }
  
  setName = action(name => this.name = name);
  setEmail = action(email => this.email = email);
  setMainMarket = action(mainMarket => this.mainMarket = mainMarket);
  setCurrentAsin = action(asin => this.currentAsin = asin);
  addAsin = action(asin => this.asins = [...this.asins, {
    asin,
    goal: 'Profit',
    weeklyBudget: '',
  }])
  clearCurrentAsin = action(() => this.currentAsin = '');
  addCurrentAsin = action(() => {
    if(this.currentAsin) {
      this.addAsin(this.currentAsin)
      this.clearCurrentAsin();
    }
  });
  deleteAsin = action(asin => {
    console.log(asin, this.asins.filter(currentAsin => currentAsin !== asin))
    this.asins = this.asins.filter(currentAsin => currentAsin !== asin);
  });
  setAsinGoal = action((asin, goal) => {
    this.asins = this.asins.map(asinObj => {
      if(asinObj.asin === asin) return { ...asinObj, goal };
      else return asinObj;
    })
  });
  setAsinWeeklyBudget = action((asin,weeklyBudget) => {
    this.asins = this.asins.map(asinObj => {
      if(asinObj.asin === asin) return { ...asinObj, weeklyBudget };
      else return asinObj;
    })
  });
  createPayment = action(async stripe => {
    try {
      let { token } = await stripe.createToken({name: this.name});
      let response = await fetch("https://api.bidmatik.com/v1/create_customer", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          tokenId: token.id,
          name: this.name,
          email: this.email,
          mainMarket: this.mainMarket,
          asins: this.asins,
          price: this.price
        })
      });
      console.log(response);
    } catch (e) {
      console.log(e)
    }

    // if(response.ok) indicate ui
  });
}

const store = {
  app: new AppStore(),
  router: new RouterStore(),
};

startRouter(routes, store);

export default () =>
  <Provider store={store} >
    <StripeProvider apiKey="pk_test_nSVmnEwaQlLHewo74vCoFphG">
      <Grommet>
        <MobxRouter />
        {/* <DevTools/> */}
      </Grommet>
    </StripeProvider>
  </Provider>
;
